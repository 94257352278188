import { render, staticRenderFns } from "./my-page.vue?vue&type=template&id=52e12a5e&scoped=true&"
import script from "./my-page.vue?vue&type=script&lang=ts&"
export * from "./my-page.vue?vue&type=script&lang=ts&"
import style0 from "./my-page.vue?vue&type=style&index=0&id=52e12a5e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52e12a5e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VContainer,VImg,VRow,VSheet,VSkeletonLoader,VSpacer})
