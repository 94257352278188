import { action, computed, IReactionDisposer, observable, reaction, runInAction, when } from 'mobx'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { TagModel } from '@/models/post-model'
import { apiService } from '@/services/api-services'
import { asyncAction } from 'mobx-utils'
import { PostsStore } from '@/stores/posts-store'
import { appProvider } from '@/app-provider'
import { walletStore } from '@/stores/wallet-store'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { postController } from '@/modules/common/dialogs/post/post-controller'
import { PostStore } from '@/stores/post-store'
import { UserProfileStore } from '@/stores/userProfileStore'
import { DaoStore } from '@/stores/dao-store'
import { communityOwnerBoardController } from '@/modules/common/components/section/owner-section-controller'
import moment from 'moment'
export class MyPageViewModel {
  @observable postsStore?: PostsStore
  @observable hotTags: TagModel[] = []
  @observable hotTagPage = 0
  @observable totalHotTags = 0
  @observable hotTagUpdating = false
  @observable tagFilter = false
  @observable selectedTag?: TagModel
  @observable profileTotalPost = 0
  @observable profileTotalLiked = 0
  @observable postListFilters = ['Latest', 'Top']
  @observable postListTitle = 'Following'
  @observable selectedPostFilter = this.postListFilters[0]
  @observable isListView = true
  @observable tabsFilter = [
    { title: 'Following', query: 'following' },
    { title: 'All post', query: 'all-post' },
    { title: 'Top', query: 'top' },
    { title: 'User', query: 'user' },
    { title: 'DAO', query: 'dao' },
  ]
  @observable selectedTab = this.tabsFilter[0]

  @observable loadingMore = false
  //All Post States
  @observable allPostsFetching = false
  @observable allPosts: PostStore[] = []
  @observable allPostTotal = 0
  @observable allPostFilter = { _start: 0, _limit: 9, status: 'public', _sort: 'createdAt:desc' }
  @observable allPostsLoadMoreState = { _start: 0 }

  //Top posts state
  @observable topPostsFetching = false
  @observable topPosts: PostStore[] = []
  @observable topPostTotal = 0
  @observable topPostFilter = { _start: 0, _limit: 9, status: 'public', _sort: 'topScore:desc' }
  @observable topPostsLoadMoreState = { _start: 0 }

  // User state
  @observable usersFetching = false
  @observable users: UserProfileStore[] = []
  @observable usersTotal = 0
  @observable usersFilter = { _start: 0, _limit: 20, _sort: 'totalPosts:desc' }
  @observable usersLoadMoreState = { _start: 0 }

  // Dao state
  @observable daosFetching = false
  @observable daos: DaoStore[] = []
  @observable daosTotal = 0
  @observable daosFilter = { _start: 0, _limit: 20, _sort: 'created:desc' }
  @observable daoLoadMoreState = { _start: 0 }
  @observable openEncouragePostDialog = false
  @observable isNoPostInSomeDays = false

  private _disposers: IReactionDisposer[]

  @computed get postStores() {
    return this.postsStore?.posts
  }

  /**
   * CREATE POST
   */

  constructor() {
    this.fetchData()
    this._disposers = [
      reaction(
        () => walletStore.userInfo?.profile,
        (profile) => {
          if (profile) this.fetchProfileData()
          if (walletStore.isFirstLogin) {
            this.openEncouragePostDialog = true
            walletStore.changeFirstLoginState(false)
          }
        },
        { fireImmediately: true }
      ),
      reaction(
        () => createPostController.completedUpdateType,
        (type) => {
          if (type) {
            createPostController.changeCompletedUpdateType(undefined)
            this.fetchData()
          }
        }
      ),
      reaction(
        () => postController.completeUpdateType,
        (type) => {
          if (type) {
            postController.changeCompleteUpdateType(undefined)
            this.fetchData()
          }
        }
      ),
    ]
  }
  @asyncAction *checkLastestPost() {
    try {
      const posts = yield apiService.posts.find(
        { profile: walletStore.userProfile?._id, status: 'public' },
        { _limit: 1, _sort: 'createdAt:DESC' }
      )
      const post = posts[0]
      if (post?.createdAt) {
        const targetTime = moment(post?.createdAt).add(3, 'days')
        this.isNoPostInSomeDays = !moment().isBefore(targetTime)
        return
      }
      this.isNoPostInSomeDays = false
    } catch (e) {
      console.log('Error checkLastestPost', e)
    }
  }

  @action changeOpenEncouragePostDialog(value) {
    this.openEncouragePostDialog = value
  }

  @action changeListView(value) {
    this.isListView = value
  }

  @action.bound onTabChange(tab: string) {
    const selectedTab = this.tabsFilter.find((item) => item.title === tab)
    if (selectedTab) {
      this.selectedTab = selectedTab
      appProvider.router.replace({ query: { tab: selectedTab.query } })
    }
  }

  @action changePostListFilter(value) {
    this.selectedPostFilter = value
    if (this.selectedPostFilter === this.postListFilters[0]) {
      this.postsStore?.changeFilter({ _sort: 'createdAt:desc' }, true)
    } else if (this.selectedPostFilter === this.postListFilters[1]) {
      this.postsStore?.changeFilter({ _sort: 'topScore:desc' })
    }
  }

  @action destroy() {
    this._disposers.forEach((d) => d())
    this.postsStore?.destroy()
  }

  @asyncAction *fetchData() {
    try {
      this.checkLastestPost()
      this.postsStore = new PostsStore({ _sort: 'createdAt:desc' }, true)
      yield this.fetchHotTags()
    } catch (e) {
      console.log('err', e)
    }
  }

  @asyncAction *fetchContentByTab(tab: string) {
    if (tab === 'following') {
      console.log('=========fetch My feed')
    } else if (tab === 'all-post') {
      yield this.fetchAllPost()
    } else if (tab === 'top') {
      yield this.fetchTopPost()
    } else if (tab === 'user') {
      yield this.fetchUsersProfile()
    } else if (tab === 'dao') {
      yield this.fetchDaos()
    }
  }

  @action setTagFilter(value: boolean) {
    this.tagFilter = value
  }

  @asyncAction *fetchSelectedTag() {
    const tagId = appProvider.router.currentRoute.query.tags
    const tags = yield apiService.tags.find({ id: tagId })
    this.selectedTag = tags[0]
  }

  @asyncAction *fetchHotTags() {
    try {
      this.hotTagUpdating = true
      const res = yield apiService.posts.getHotTags()
      if (res) {
        this.hotTags = res.data
        this.totalHotTags = res.total
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.hotTagUpdating = false
    }
  }

  @asyncAction *updateHotTags() {
    try {
      this.hotTagUpdating = true
      if (this.hotTagPage + 6 <= this.totalHotTags) this.hotTagPage += 1
      else this.hotTagPage = 0
      const res = yield apiService.posts.getHotTags({ page: this.hotTagPage + 1 })
      if (res) this.hotTags = res.data
    } catch (error) {
    } finally {
      this.hotTagUpdating = false
    }
  }

  @asyncAction *fetchProfileData() {
    const profile = walletStore.userInfo?.profile?._id
    if (profile) {
      this.profileTotalPost = yield apiService.posts.count({ profile })
      this.profileTotalLiked = yield apiService.likes.count({ profile })
    }
  }

  @asyncAction *followTag(tag: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      tag.loading = true
      yield apiService.tagFollows.followTag({ tag: tag })
      tag.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      tag.loading = false
    }
  }

  @asyncAction *unFollowTag(tag: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      tag.loading = true
      yield apiService.tagFollows.unFollowTag({ tag: tag })
      tag.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      tag.loading = false
    }
  }

  @asyncAction *fetchAllPost() {
    try {
      this.allPostsFetching = true
      const posts = yield apiService.posts.fetchPosts(this.allPostFilter)
      if (posts) {
        this.allPosts = posts.map((item) => new PostStore(item))
        this.allPostTotal = posts[0]?.total || 0
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.allPostsFetching = false
    }
  }

  @asyncAction *allPostsLoadMore() {
    try {
      this.loadingMore = true
      this.allPostsLoadMoreState = {
        ...this.allPostFilter,
        _start: this.allPostsLoadMoreState._start + this.allPostFilter._limit,
      }
      const postMore = yield apiService.posts.fetchPosts({ ...this.allPostsLoadMoreState })
      const newPost = postMore.map((post) => new PostStore(post))
      const currentPost = this.allPosts
      this.allPosts = [...currentPost, ...newPost]
    } catch (error) {
    } finally {
      this.loadingMore = false
    }
  }

  @computed get allPostsCanLoadMore() {
    if (this.allPosts.length < this.allPostTotal) return true
    else return false
  }

  @asyncAction *fetchTopPost() {
    try {
      this.topPostsFetching = true
      const posts = yield apiService.posts.fetchPosts(this.topPostFilter)
      if (posts) {
        this.topPosts = posts.map((item) => new PostStore(item))
        this.topPostTotal = posts[0]?.total || 0
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.topPostsFetching = false
    }
  }

  @asyncAction *topPostsLoadMore() {
    try {
      this.loadingMore = true
      this.topPostsLoadMoreState = {
        ...this.topPostFilter,
        _start: this.topPostsLoadMoreState._start + this.topPostFilter._limit,
      }
      const postMore = yield apiService.posts.fetchPosts({ ...this.topPostsLoadMoreState })
      const newPost = postMore.map((post) => new PostStore(post))
      const currentPost = this.topPosts
      this.topPosts = [...currentPost, ...newPost]
    } catch (error) {
    } finally {
      this.loadingMore = false
    }
  }

  @computed get topPostsCanLoadMore() {
    if (this.topPosts.length < this.topPostTotal) return true
    else return false
  }

  @asyncAction *fetchUsersProfile() {
    try {
      this.usersFetching = true
      const res = yield apiService.profiles.find(this.usersFilter)
      if (res) {
        this.users = res.map((profile) => new UserProfileStore(profile))
        this.usersTotal = yield apiService.profiles.count({})
      }
    } catch (error) {
    } finally {
      this.usersFetching = false
    }
  }

  @asyncAction *usersLoadMore() {
    try {
      this.loadingMore = true
      this.usersLoadMoreState = {
        ...this.usersFilter,
        _start: this.usersLoadMoreState._start + this.usersFilter._limit,
      }
      const res = yield apiService.profiles.find(this.usersLoadMoreState)
      const newUsersToFollow = res.map((profile) => new UserProfileStore(profile))
      this.users = [...this.users, ...newUsersToFollow]
    } catch (error) {
    } finally {
      this.loadingMore = false
    }
  }

  @asyncAction *fetchDaos() {
    try {
      this.daosFetching = true
      const res = yield apiService.daos.getDaosToJoin(this.daosFilter)
      if (res) {
        const daos = res.data.map((dao) => new DaoStore(dao))
        this.daos = daos
        this.daosTotal = res.total
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.daosFetching = false
    }
  }

  @asyncAction *daosLoadMore() {
    try {
      this.loadingMore = true
      this.daoLoadMoreState = { ...this.daosFilter, _start: this.daoLoadMoreState._start + this.daosFilter._limit }
      const res = yield apiService.daos.getDaosToJoin(this.daoLoadMoreState)
      const newDaos = res.data.map((dao) => new DaoStore(dao))
      this.daos = [...this.daos, ...newDaos]
    } catch (error) {
    } finally {
      this.loadingMore = false
    }
  }

  @computed get daosCanLoadMore() {
    return this.daos.length < this.daosTotal
  }

  @computed get usersCanLoadMore() {
    if (this.users.length < this.usersTotal) return true
    else return false
  }

  @computed get usersDisplayed() {
    return this.users.filter((userProfileStore) => userProfileStore.profile._id !== walletStore.userProfile?._id)
  }

  get userProfileId() {
    if (walletStore.userInfo) return walletStore.userInfo.profile?._id
    else return ''
  }

  @computed get tabs() {
    return this.tabsFilter.map((tab) => tab.title)
  }

  @computed get tab() {
    return this.selectedTab.title
  }

  @computed get showMyFeed() {
    return this.tab === 'Following'
  }

  @computed get showAllPosts() {
    return this.tab === 'All post'
  }

  @computed get showTopPosts() {
    return this.tab === 'Top'
  }

  @computed get showUsers() {
    return this.tab === 'User'
  }

  @computed get showDaos() {
    return this.tab === 'DAO'
  }
  @computed get isNoPost() {
    return communityOwnerBoardController?.totalPosts == 0
  }
  @computed get showEncourageFirstPost() {
    return this.isNoPost || this.isNoPostInSomeDays
  }
}
