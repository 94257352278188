














































































































































































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { MyPageViewModel } from '../viewmodels/my-page-viewmodel'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { appProvider } from '@/app-provider'

@Observer
@Component({
  components: {
    'owner-section': () => import('@/modules/common/components/section/owner-section.vue'),
    'my-dao-section': () => import('@/modules/common/components/section/my-dao-section.vue'),
    'most-active-user-section': () => import('@/modules/common/components/section/most-active-user-section.vue'),
    'most-active-dao-section': () => import('@/modules/common/components/section/most-active-dao-section.vue'),
    'post-list': () => import('@/modules/community/common/components/post-list.vue'),
    'post-grid': () => import('@/modules/community/common/components/post-grid.vue'),
    search: () => import('@/components/search/search.vue'),
    'my-feed': () => import('../components/my-page/my-feed.vue'),
    'all-posts': () => import('../components/my-page/all-posts.vue'),
    'top-posts': () => import('@/modules/community/home/components/my-page/top-post.vue'),
    users: () => import('@/modules/community/home/components/my-page/users.vue'),
    daos: () => import('@/modules/community/home/components/my-page/daos.vue'),
    'encourage-post-dialog': () => import('@/modules/community/home/dialog/encourage-post-dialog.vue'),
    'encourage-first-post': () => import('@/modules/community/home/components/my-page/encourage-first-post.vue'),
    'presale-mystery-box-noti': () =>
      import('@/modules/common/components/notify-templates/presale-mystery-box-noti.vue'),
  },
})
export default class ConnectWalletStatus extends Vue {
  @Provide() vm = new MyPageViewModel()
  createPostController = createPostController
  appProvider = appProvider

  @Watch('$vuetify.breakpoint.width', { immediate: true }) onResize(val: number) {
    if (val <= 1480) {
      this.vm.changeListView(true)
    }
  }

  @Watch('$route.query.tab', { immediate: true }) queryChange(tab: string) {
    if (tab) {
      const tabSelect = this.vm.tabsFilter.find((item) => item.query === tab)
      if (tabSelect) {
        this.vm.selectedTab = tabSelect
        this.vm.fetchContentByTab(tab)
      }
    }
  }

  redirectTrending(tagContent?: string): void {
    const searchTerm = tagContent?.replaceAll('#', '')
    this.$router.push(`/search/forum?text=${searchTerm}`)
  }

  openLink(link) {
    window.open(link, '_blank')
  }

  destroyed() {
    this.vm.destroy()
  }
}
